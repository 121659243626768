<template>
  <div class="terms">
    <h1>Termos de uso</h1>
    <p>
      {{ $store.state.razao_social }}, {{ $store.state.sociedade }}, sediada em
      {{ $store.state.address }}, e inscrita no CNPJ/MF sob o nº
      {{ $store.state.cnpj }}, doravante denominada apenas “MY BABY BOOK”,
      proprietária do site
      <a href="https://mybabybookapp.com">mybabybookapp.com</a>, por este
      instrumento e na melhor forma de direito estabelece as regras e condições
      a serem observadas e estritamente seguidas pelo USUÁRIO, que uma vez que
      tenha manifestado o seu "CONCORDO" no campo ao final delimitado, terá
      celebrado o presente instrumento de prestação de serviços e outras
      avenças, nos termos das cláusulas que se seguem. No entanto, caso você
      USUÁRIO não concorde com os termos ora estabelecidos, deve clicar em
      "CANCELAR" no campo específico ao final delimitado, o que impedirá a
      formação deste instrumento e, consequentemente, a prestação de quaisquer
      serviços por parte do MY BABY BOOK.
    </p>
    <h2>1. REQUISITOS PARA ACESSO AOS SERVIÇOS DISPONIBILIZADOS</h2>
    <p>
      1.1. Ao clicar em “CONCORDO”, você estará aderindo a todos os termos desse
      documento e confirmando as informações prestadas quando preencheu seu
      cadastro pessoal. A partir disso, poderá utilizar os serviços fornecidos
      pelo SITE e APLICATIVO, também denominado “My Baby Book”.
    </p>
    <p>
      1.1.1. Os USUÁRIOS menores de dezoito anos só estão autorizados a
      contratar os SERVIÇOS e PRODUTOS disponibilizados pelo “MY BABY BOOK” se,
      efetivamente, assistidos ou representados por seus pais responsáveis,
      tutores ou representantes legais, devendo essa informação estar
      expressamente informada no cadastro preenchido.
    </p>
    <p>
      1.1.2. Ao preencher o cadastro, o USUÁRIO irá determinar a senha que
      deseja utilizar. Essa senha deve ser composta de, no mínimo, seis
      caracteres alfanuméricos.
    </p>
    <p>
      1.1.3. O USUÁRIO é o único responsável pelas informações fornecidas no
      cadastro. Assim, qualquer tentativa de criar falsa identidade, idade,
      endereço eletrônico ou qualquer outra informação, será considerada
      infração à lei e às determinações deste Termo de Uso, o que ensejará sua
      automática e imediata rescisão, sem que o “MY BABY BOOK”, seus
      contratados, prestadores de serviços ou parceiros sejam responsabilizados
      por indenizações ao USUÁRIO ou a terceiros a quem esse prejudique em
      virtude das falsas informações prestadas.
    </p>
    <p>
      1.1.4. Caso sejam identificadas falsidades nas alegações prestadas pelo
      USUÁRIO ao “MY BABY BOOK”, esse se reserva ao direito de considerar
      automaticamente rescindido este instrumento, sem prejuízo de eventuais
      indenizações devidas pelo USUÁRIO devido aos danos sofridos, sendo
      possível, ainda, que o “MY BABY BOOK”, seus contratos, prestadores de
      serviços ou parceiros, a seu exclusivo critério, acionar as autoridades
      competentes sobre o ocorrido e colaborar de todas as forma, inclusive com
      a revelação de conteúdo da ÁREA PESSOAL do USUÁRIO, para apuração dos
      danos e delitos praticados.
    </p>

    <p>
      1.1.5. As declarações abaixo reproduzidas são consideradas como prestadas
      pelo (s) USUÁRIO (S):
    </p>
    <ol class="list-alpha">
      <li>
        Eu, como USUÁRIO, obrigo-me a utilizar, no SITE e APLICATIVO “MY BABY
        BOOK” apenas imagens de minha autoria e aquelas sobre as quais eu tenha
        legítimo interesse de uso, e, assim, isento a empresa responsável pela
        aplicação, “MY BABY BOOK”, seus contratados, parceiros ou prestadores de
        serviço de quaisquer responsabilidades relacionadas a isso, pois
        compreendo que não cabe a esses questionar a origem das imagens que
        utilizarei no meu álbum.
      </li>
      <li>
        Eu, como USUÁRIO, me obrigo a não utilizar essa plataforma para
        propósitos ilegais ou que violem direitos ou propriedade intelectual de
        terceiros, como, por exemplo, regras relativas a marcas e patentes, mas
        não se restringindo a isso.
      </li>
      <li>
        Eu, como USUÁRIO, me obrigo a não utilizar, nessa plataforma, imagens de
        terceiros que não tenham me dado, expressamente, autorização para essa
        utilização.
      </li>
      <li>
        Eu, como USUÁRIO, declaro ter, pelo menos 18 anos. Menores de idade
        somente podem usar os serviços e produtos fornecidos pelo “MY BABY BOOK”
        sob a supervisão de um adulto.
      </li>
      <li>
        Eu, USUÁRIO, me obrigo a não utilizar o “MY BABY BOOK” para arquivamento
        de conteúdo que pode ser considerado ofensivo, pornográfico, obsceno,
        ameaçador ou qualquer outro que possa gerar ação de indenização no
        âmbito do direito civil ou que possa ser considerado crime, contravenção
        penal, mesmo que em tese, incluindo material que venha a ferir a ética,
        a moral e os bons costumes, e que possa incitar discriminação de
        qualquer espécie (racial, cultural, religiosa, sexual ou outra)
      </li>
      <li>
        Eu, como USUÁRIO, me obrigo a indenizar o “MY BABY BOOK”, seus
        contratados, prestadores de serviços ou parceiros, por quaisquer
        prejuízos, seja de cunho material ou de cunho moral, que venham a sofrer
        em decorrência do não cumprimento do que está estabelecido neste
        documento, por qualquer condenação em qualquer esfera (administrativa ou
        judicial), em decorrência da utilização irregular ou ilícita dos
        SERVIÇOS e PRODUTOS disponibilizados pelo SITE e APLICATIVO. Também me
        responsabilizo pelas custas e honorários advocatícios que possam ser
        despendidos na defesa dos interesses do “MY BABY BOOK”, seus
        contratados, prestadores de serviços ou parceiros. Me responsabilizo,
        também, por indenizar terceiros ou o “MY BABY BOOK” por danos causados
        pela utilização dos SERVIÇOS ou PRODUTOS aqui prestados em desacordo com
        este Termo de Uso.
      </li>
    </ol>

    <h2>2. OBJETO</h2>
    <p>
      2.1. Constitui o objeto do presente instrumento a prestação de serviços de
      disponibilização de espaço para o arquivamento de imagens, vídeos, fotos,
      textos ou arquivos em formato digital (IMAGENS ou ARQUIVOS), em ambiente
      específico e determinado, acessível via SITE e APLICATIVO (denominado ÁREA
      PESSOAL), para exibição ao USUÁRIO e pessoas por este autorizadas
      (CONVIDADOS), cujo único intuito é a utilização desses CONTEÚDOS para
      formação de ALBÚM DE RECORDAÇÕES DIGITAL DO BEBÊ.
    </p>
    <p>
      2.1.1. As imagens, vídeos, fotos, textos ou arquivos em formato digital
      objeto do item acima, serão disponibilizadas pelo SITE e APLICATIVO em
      caráter não exclusivo, ficando a critério do USUÁRIO o seu arquivamento em
      outros sites com a mesma natureza ou natureza semelhante.
    </p>
    <p>
      2.1.2. O SITE e APLICATIVO disponibilizarão para download gratuito pelo
      USUÁRIO um software, compatível com plataformas Android ou IOS, que
      possibilitará o armazenamento de imagens, vídeos e textos para a formação
      do ALBUM DIGITAL, o qual poderá ser acessado através do próprio APLICATIVO
      ou SITE.
    </p>
    <p>
      2.1.3. Com o intuito de aperfeiçoar o funcionamento do software ou
      atualizar os serviços disponibilizados, o “MY BABY BOOK” poderá,
      diretamente, ou através de seus parceiros, contratados ou prestadores de
      serviços, realizar modificações na versão do sistema instalado no
      dispositivo do USUÁRIO (update automático).
    </p>
    <p>
      2.1.4. Tendo em vista a possibilidade de falhas no sistema de comunicação
      do USUÁRIO durante o download, e ainda a configuração do dispositivo do
      USUÁRIO, o “MY BABY BOOK” não garante a funcionalidade do referido
      software, nem se responsabiliza pela sua incorreta utilização ou pelas
      consequências que possam advir da sua eventual utilização, inclusive no
      que tange ao update automático.
    </p>
    <p>
      2.1.5. É vedada a divulgação e a venda de produtos ou serviços de
      terceiros por intermédio do “MY BABY BOOK”, ou através da ÁREA PESSOAL do
      USUÁRIO.
    </p>
    <p>
      2.1.6. O “MY BABY BOOK” poderá ser utilizado por pessoas nacionais e
      estrangeiras, submetendo-se estas últimas, desde já, à legislação
      brasileira e autorizando expressamente que suas IMAGENS possam ser
      processadas no Brasil.
    </p>
    <h2>
      3. DAS IMAGENS ENCAMINHADAS E ARQUIVADAS NA ÁREA PESSOAL DO USUÁRIO:
    </h2>
    <p>
      3.1. O USUÁRIO encaminhará as imagens, vídeos, fotos, textos ou arquivos
      em formato digital ao “MY BABY BOOK” por meio digital (upload), sendo
      recebidas as IMAGENS, VÍDEOS e TEXTOS com tamanho máximo de 200 MB cada.
    </p>
    <p>
      3.1.1. A qualidade de apresentação das IMAGENS gravadas no “MY BABY BOOK”
      dependerá da resolução e qualidade (nitidez, foco, balanço de cores,
      brilho e contraste) do ARQUIVO enviado pelo USUÁRIO e será de sua
      exclusiva responsabilidade.
    </p>
    <p>
      3.1.2 O “MY BABY BOOK”, seus contratados, parceiros ou prestadores de
      serviço não se comprometem nem se responsabilizam pela guarda,
      restauração, recuperação ou devolução de IMAGENS recebidas, uma vez que
      competirá ao próprio USUÁRIO o armazenamento das IMAGENS, sejam elas
      originais ou cópias, SENDO OBRIGAÇÃO DO USUÁRIO A MANUTENÇÃO DE CÓPIA DE
      SEGURANÇA (backup) DE TODAS AS IMAGENS E/OU ARQUIVOS ENVIADOS AO SITE e
      APLICATIVO.
    </p>
    <h2>4. SEGURANÇA</h2>
    <p>
      4.1. O “MY BABY BOOK”, como forma de buscar a manutenção do seu sistema e
      com intuito de proteger e resguardar a privacidade e a segurança das
      informações dos seus USUÁRIOS, adotará os melhores recursos para evitar
      que terceiros não autorizados pelo próprio USUÁRIO tenham acesso à ÁREA
      PESSOAL desse.
    </p>
    <p>
      4.1.1. Com este intuito serão disponibilizadas ao USUÁRIO uma série de
      medidas de segurança, cabendo ao mesmo, no momento em que formalizar o
      presente instrumento cadastrar sua senha pessoal de no mínimo 6 caracteres
      alfanuméricos, senha esta que desativará o sistema de segurança
      desenvolvido e permitirá a visualização e eventual edição dos arquivos e
      imagens armazenados na ÁREA PESSOAL do USUÁRIO.
    </p>
    <p>
      4.1.2. Registrada a senha, o USUÁRIO somente poderá ter acesso à sua ÁREA
      PESSOAL mediante a informação dessa, devendo zelar pela sua guarda e
      utilização de modo a impedir que terceiros também tomem conhecimento da
      mesma.
    </p>
    <p>
      4.2. A senha é pessoal e intransferível, cabendo ao USUÁRIO a exclusiva
      responsabilidade pela sua guarda e conservação e o seu não fornecimento a
      terceiros. Frisa-se que o fornecimento da senha a terceiros permitirá o
      acesso destes à ÁREA PESSOAL do USUÁRIO, posto que este terceiro será
      reconhecido pelo “MY BABY BOOK” como se o USUÁRIO fosse.
    </p>
    <p>
      4.3. O USUÁRIO, no entanto, mediante o fornecimento de link específico
      poderá permitir que outras pessoas, acesso à sua ÁREA PESSOAL, no todo ou
      em parte, responsabilizando-se o próprio USUÁRIO pelo acesso efetuado
      pelos seus CONVIDADOS à(s) área(s) que determinou.
    </p>
    <h2>5. PRIVACIDADE</h2>
    <p>
      5.1. As informações colhidas junto ao USUÁRIO (nome, idade, e-mail,
      celular, data de nascimento e país), serão utilizadas para os propósitos
      de cadastro como definido no item I supra e serão utilizadas para os fins
      do presente instrumento.
    </p>
    <p>
      5.2. Caso o USUÁRIO autorize, mediante opção no campo próprio durante o
      processo de cadastramento, o “MY BABY BOOK”, seus contratados, parceiros
      ou prestadores de serviço poderão enviar ao mesmo, avisos de promoções e
      novas informações, como, por exemplo, sobre novos serviços e produtos.
    </p>
    <p>
      5.2.1. Caso o USUÁRIO não deseje mais receber tais informações, poderá
      alterar a opção de autorização na área de edição dos dados pessoais. No
      mais o “MY BABY BOOK”, seus contratados, parceiros ou prestadores de
      serviço apenas se utilizarão do e-mail do USUÁRIO para confirmar status de
      pedidos, prestar suporte ou fornecer informações consideradas úteis a
      esse. Em relação a tais serviços ou produtos adicionais, pode ocorrer que
      o “MY BABY BOOK” precise solicitar informações adicionais ao USUÁRIO para
      fornecimento e/ou prestação dos mesmos.
    </p>
    <p>
      5.2.2. O “MY BABY BOOK” respeita a sua
      <router-link :to="to('/privacy')">privacidade</router-link> e é contra o
      spam. Ademais, não vende ou distribui seu cadastro a terceiros, pois faz
      parte de nossa política de respeito ao consumidor. Caso não deseje receber
      os e-mails aos quais se refere o item acima, solicite o opt-out clicando
      no link ao final de cada e-mail ou atualize as preferências de sua conta.
    </p>
    <p>
      5.3 Para fins estatísticos, o “MY BABY BOOK”, seus contratados, parceiros
      ou prestadores de serviço poderão utilizar as informações disponibilizadas
      pelo USUÁRIO através do seu cadastro, bem como o modo, fins e horários de
      utilização dos serviços e, porventura, de possíveis anúncios apresentados,
      quando for o caso, sem, no entanto, identificar o USUÁRIO, o e-mail ou
      qualquer outra informação específica a respeito do mesmo.
    </p>
    <p>
      5.4. O “MY BABY BOOK” não editará ou modificará o conteúdo da ÁREA PESSOAL
      do USUÁRIO.
    </p>
    <p>
      5.4.1. O USUÁRIO concorda que, se o “MY BABY BOOK”, seus contratados,
      parceiros ou prestadores de serviço por ocasião das referidas verificações
      periódicas se depararem com IMAGENS ou ARQUIVOS cujo conteúdo seja, ao seu
      exclusivo critério, considerado ofensivo, pornográfico (envolvendo ou não
      menores de idade), obsceno, ameaçador ou contenha qualquer material que
      possa gerar ação indenizatória no âmbito do direito civil ou que seja
      considerado crime ou contravenção penal, ou ainda que ofenda a regra
      estatuída no item 2.2. deste, as ditas IMAGENS poderão ser excluída da
      base de dados da plataforma, além de haver a possibilidade de rescisão do
      presente instrumento, sem que seja devido ao USUÁRIO qualquer tipo de
      indenização.
    </p>
    <p>
      5.5. O “MY BABY BOOK” se compromete a avisar ao USUÁRIO, caso venha a
      verificar que a privacidade de sua ÁREA PESSOAL tenha sido violada.
    </p>
    <p>
      5.6. De sua parte o USUÁRIO se compromete a não invadir a ÁREA PESSOAL de
      qualquer outro USUÁRIO, por intermédio de eventual falha ocorrida no
      sistema que lhe permita eventual acesso não autorizado. Compromete-se
      ainda o USUÁRIO a comunicar imediatamente ao “MY BABY BOOK” qualquer falha
      ou possibilidade de quebra de segurança da plataforma que venha a tomar
      conhecimento.
    </p>
    <p>
      5.7. O USUÁRIO fica obrigado a manter atualizados os seus dados
      cadastrais, a fim de que o “MY BABY BOOK”, seus contratados, parceiros ou
      prestadores de serviço possam entrar em contato com o mesmo.
    </p>
    <h2>6. ACESSO PÚBLICO</h2>
    <p>
      6.1. A ÁREA PESSOAL do USUÁRIO agrupará imagens, vídeos, fotos, textos ou
      arquivos em formato digital que poderão vir a ser divididas e organizadas
      em ÁLBUNS.
    </p>
    <p>
      6.1.1. Os ÁLBUNS, cujos conteúdos são de única e exclusiva
      responsabilidade do USUÁRIO poderão vir a ser compartilhados por meio de
      link.
    </p>
    <p>
      6.1.2. Cabe ao USUÁRIO selecionar as pessoas com as quais deseja
      compartilhar o conteúdo dos seus álbuns, estando isento o “MY BABY BOOK”
      de quaisquer questões decorrentes deste compartilhamento.
    </p>
    <h2>7. VIGÊNCIA</h2>
    <p>
      7.1. Este contrato durará por tempo indeterminado ou durante o período em
      que o “MY BABY BOOK” estiver disponibilizando seus serviços para o
      USUÁRIO.
    </p>
    <h2>8. CANCELAMENTO OU RESCISÃO</h2>
    <p>
      8.1. O USUÁRIO poderá cancelar a contratação dos serviços da “MY BABY
      BOOK” de acordo com os termos que forem definidos no momento de sua
      contratação, através de comunicação escrita formulada por meio de e-mail
      para o endereço cadastrado do USUÁRIO ou por meio do acesso ao “MY BABY
      BOOK”, nos seguintes termos:
    </p>
    <ol class="list-alpha">
      <li>
        o usuário poderá cancelar os serviços em até 7 (sete) dias após a
        contratação do serviço, de acordo com o Código de Defesa do Consumidor;
      </li>
      <li>
        para os cancelamentos solicitados após 7 dias, os serviços somente
        cessarão quando concluído o ciclo vigente ao tempo do cancelamento.
      </li>
    </ol>

    <p>
      8.2. Os serviços poderão ser rescindidos por parte do “MY BABY BOOK”, nos
      seguintes casos:
    </p>
    <ol class="list-alpha">
      <li>
        por comprovada inadimplência das cláusulas e condições estipuladas no
        presente contrato, sem prejuízo de eventual indenização devida à parte
        inocente;
      </li>
      <li>
        violação dos Termos de Uso: os serviços serão cessados imediatamente;
      </li>
      <li>em caso de falência ou concordata do “MY BABY BOOK”.</li>
    </ol>

    <p>
      8.3. Após o prazo de rescisão do contrato, a ÁREA PESSOAL do USUÁRIO e
      consequentemente todas as imagens, vídeos, fotos, textos ou arquivos em
      formato digital a ela associados serão definitivamente apagadas, ficando
      rescindido o presente instrumento.
    </p>
    <p>
      8.4. Se por iniciativa do USUÁRIO o presente contrato venha a ser
      rescindido, este poderá solicitar a reativação de sua ÁREA PESSOAL,
      mediante o preenchimento de novo cadastro e posterior envio (upload) das
      imagens, vídeos, fotos, textos ou arquivos em formato digital que desejam
      ver armazenadas.
    </p>
    <p>
      8.5. Se a rescisão tenha sido solicitada pelo “MY BABY BOOK”, este poderá,
      independentemente de qualquer justificativa, deixar de aceitar novo pedido
      de cadastramento do USUÁRIO.
    </p>
    <p>
      8.6. O “MY BABY BOOK”, seus contratados, parceiros ou prestadores de
      serviço não poderão ser responsabilizados por qualquer ato, fato, produto
      ou serviço que venha ser irregularmente divulgado ou negociado através do
      ÁLBUM registrado na ÁREA PESSOAL do USUÁRIO, ficando desde já assentado
      que a utilização irregular da ÁREA PESSOAL do USUÁRIO não pode ser
      interpretada e não induzirá a existência de parceria ou contrato de
      associação entre os mesmos.
    </p>
    <h2>9. CESSÃO E TRANSFERÊNCIA</h2>
    <p>
      9.1. Os USUÁRIOS não poderão transferir ou ceder este contrato, a
      terceiros, sem a prévia e expressa anuência por escrito do “MY BABY BOOK”.
    </p>
    <h2>10. PAGAMENTO</h2>
    <p>
      10.1. O SERVIÇO de armazenagem digital de imagens, vídeos, fotos, textos
      ou arquivos em formato digital serão prestados pelo “MY BABY BOOK”, mas o
      pagamento e gerenciamento das assinaturas serão realizados por “GOOGLE
      PLAY STORE” e "APP STORE (Apple)", que garantirá todos os padrões de
      segurança e confidencialidade.
    </p>
    <p>
      10.2. Os VALORES referentes a este contrato serão cobrados de acordo e na
      forma prevista na tabela de preços definida unilateralmente pelo “MY BABY
      BOOK” e que poderá sofrer reajustes a qualquer momento, independentemente
      de prévio aviso ao USUÁRIO.
    </p>
    <h2>11. ALTERAÇÕES</h2>
    <p>
      11.1. O “MY BABY BOOK” a qualquer momento, reserva-se o direito de limitar
      o uso de seus serviços, encerrar suas atividades ou rescindir o presente
      contrato, sem prévia notificação do USUÁRIO, desde que observadas as
      cláusulas 7 e 8.
    </p>
    <p>
      11.2. O “MY BABY BOOK” a qualquer momento, reserva-se o direito de alterar
      alguma cláusula o presente contrato, comprometendo-se a comunicar por
      escrito a alteração por meio de e-mail a ser enviado ao endereço
      cadastrado do USUÁRIO, concedendo o prazo de 7 (sete) dias para que ele
      concorde ou rejeite as alterações.
    </p>
    <p>
      11.2.1. Caso o USUÁRIO concorde com as alterações, terá prosseguimento
      normal do contrato, com as alterações nele incluídas.
    </p>
    <p>
      11.2.2. Caso o USUÁRIO não concorde expressamente com as alterações
      propostas, deverá enviar a comunicação do item 8.2 como notificação da
      rescisão contratual. O contrato terá uma vigência por mais 30 (trintas)
      dias, após a notificação, em que todas as informações do USUÁRIO serão
      totalmente apagadas do nosso banco de dados.
    </p>
    <h2>12. DIREITOS AUTORAIS DO APLICATIVO</h2>
    <p>
      12.1. O “MY BABY BOOK”, seus contratados, parceiros ou prestadores de
      serviço são titulares de todos os documentos, informações e materiais
      contidos no SITE e APLICATIVO, com exceção das imagens, vídeos, fotos,
      textos ou arquivos em formato digital. Assim, a reprodução desses
      materiais fora do SITE e APLICATIVO é proibida, sujeitando o USUÁRIO às
      penas da lei.
    </p>
    <p>
      12.2. A marca, o nome, a logomarca e o logotipo utilizados pelo “MY BABY
      BOOK” são de sua propriedade exclusiva e encontram-se depositados junto ao
      Instituto Nacional da Propriedade Industrial.
    </p>
    <p>
      12.3. O presente contrato não confere ao USUÁRIO qualquer direito direto
      ou indireto sobre tais imagens, documentos, informações, materiais, marca,
      nome, logomarca e logotipo pertencentes ao “MY BABY BOOK”, sendo VEDADA
      qualquer forma de reprodução, utilização, exploração, cessão, locação ou
      sublocação, veiculação da marca, logo, imagens, fotografias, vídeos,
      áudios, animações, textos, sem a autorização prévia e expressa do “MY BABY
      BOOK”.
    </p>
    <h2>13. DISPOSIÇÕES GERAIS</h2>
    <p>
      13.1. O presente contrato estabelece um acordo integral entre as partes,
      predominando sobre qualquer outro consenso verbal ou escrito firmado entre
      as partes. O presente termo encontra-se armazenado com o “aceite” do
      USUÁRIO dentro do banco de dados do “MY BABY BOOK” e prevalecerá sobre
      qualquer cópia impressa pelo próprio USUÁRIO.
    </p>
    <p>
      13.2. O presente instrumento é firmado em caráter irrevogável e
      irretratável, ressalvando o direito à rescisão nas hipóteses e na forma do
      item 8.0 previsto neste termo, respondendo as partes por si, na forma da
      lei.
    </p>
    <p>
      13.3. Todas as cláusulas que impliquem ou possam implicar em restrições de
      direitos do USUÁRIO foram redigidas de forma clara, expressa e em destaque
      para fins de adequação ao disposto no art. 54, §§ 3º e 4º do Código de
      Defesa do Consumidor.
    </p>
    <h2>14. FORO</h2>
    <p>
      14.1. Para a solução de controvérsias decorrentes do presente contrato,
      serão aplicadas integralmente as leis brasileiras, ficando eleito o Foro
      da Comarca de Recife-PE, em que se encontra a sede da empresa, com
      expressa renúncia de qualquer outro, por mais privilegiado que seja, para
      dirimir qualquer dúvida, pendência ou litígio oriundo deste instrumento.
    </p>
    <h2>15. POLÍTICA DE PRIVACIDADE</h2>
    <p>
      15.1. Além deste presente Termo de Uso, o USUÁRIO deverá observar as
      disposições contidas na respectiva
      <router-link :to="to('/privacy')">Política de Privacidade</router-link>
      disponível dentro da interface do SITE e APLICATIVO.
    </p>
    <h2>16. SUPORTE</h2>
    <p>
      16.1. Em caso de qualquer sugestão, dúvida ou problema com a utilização do
      “MY BABY BOOK”, o USUÁRIO poderá entrar em contato com o suporte através
      do e-mail
      <a href="mailto:atendimento@mybabybookapp.com"
        >atendimento@mybabybookapp.com</a
      >.
    </p>
    <p>
      Estes serviços de atendimento ao USUÁRIO estarão disponíveis sempre que
      for solicitado.
    </p>
  </div>
</template>
<script>
export default {
  name: "Terms",
  methods: {
    to(path) {
      var lang = this.$i18n.locale;
      if (lang != "en" && lang != "pt-BR") {
        return path + "/en";
      }
      return path + "/" + lang;
    },
  },
};
</script>